<template>
    <div class="registerPage">
        <div class="logo" :style="{height:`${winH * 0.3}px`}">
            <img :src="logoImg ? logoImg : require('../../public/transform/defaultLogo.png')" class="login"/>
        </div>
        <div class="form_box" :style="{height:`${winH * 0.5}px`}">
            <van-form @submit="onSubmit">
                <van-field
                        v-model="phone"
                        type="tel"
                        name="用户名"
                        placeholder="请输入手机号"
                        :rules="[{ required: true, message: '请填写用户名' },{ validator, message: '请输入正确的用户名' }]"
                />
                <van-field
                        v-model="SMSCode"
                        type="text"
                        name="验证码"
                        placeholder="请输入验证码"
                        :rules="[{ required: true, message: '请输入验证码' }]"
                        @click-right-icon="getSMSCode"
                >
                    <div class="inputType" slot="right-icon">
                        <div class="get_code" v-if="descTime === 0" @click="sendSMSCode">{{SMSSend ? '重新获取' : '获取验证码'}}</div>
                        <div class="got_code" v-if="descTime > 0">已发送（{{descTime}}s）</div>
                    </div>
                </van-field>
                <van-field
                        v-model="password"
                        :type="showPassword?'text':'password'"
                        name="密码"
                        placeholder="输入6-20位字符密码"
                        :rules="[{ required: true, message: '请填写密码' }]"
                        @click-right-icon="showPassword = !showPassword"
                >
                    <div class="inputType" slot="right-icon">
                        <img v-if="!showPassword" src="../../public/iconImg/open-eye.png" alt="">
                        <img v-else src="../../public/iconImg/close-eye.png" alt="">
                    </div>
                </van-field>

                <van-field
                        v-model="confirmPassword"
                        :type="showConfirmPassword?'text':'password'"
                        name="密码"
                        placeholder="请再次输入密码"
                        :rules="[{ required: true, message: '请确认密码' }]"
                        @click-right-icon="showConfirmPassword = !showConfirmPassword"
                >
                    <div class="inputType" slot="right-icon">
                        <img v-if="!showConfirmPassword" src="../../public/iconImg/open-eye.png" alt="">
                        <img v-else src="../../public/iconImg/close-eye.png" alt="">
                    </div>
                </van-field>
                <div class="submit_btn" :class="{disabled:!submitAble}" @click="onSubmit">确认</div>
                <div class="agreement">
                    注册和登录即代表您同意 <span class="agreement_text" @click="userAction('agreement')">《服务协议及隐私政策》</span>
                </div>
            </van-form>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "Register",
        data(){
            return{
                //窗口高度
                winH:0,
                //用户名
                phone:'',
                //验证码
                SMSCode:'',
                //是否已经发送过验证码
                SMSSend:false,
                //密码
                password:'',
                //展示密码
                showPassword:false,
                //确认密码
                confirmPassword:'',
                //展示确认密码
                showConfirmPassword:false,
                //验证码倒计时
                descTime:0,
                //验证码初始化时间,默认60s，用于重置倒计时
                descTimeInit:5,
                //验证码计时器
                timer:null,

            }
        },
        mounted() {
            this.pageInit();
            this.timerStart();
        },
        computed:{
            ...mapGetters({
                //logo信息
                logoImg:'common/getLogoImg'
            }),
            //简单判断是否可以提交，用于计算按钮
            submitAble(){
                return this.$A.isMobile(this.phone)  && this.SMSCode !==''
                    && this.password.length >= 6
                    && this.password.length <= 20
                    && this.confirmPassword.length >= 6
                    && this.confirmPassword.length <= 20;
            }
        },
        methods:{
            //用户操作跳转
            userAction(type){
                let path;
                switch (type) {
                    case 'retrieve':
                        path = '/Retrieve';
                        break;
                    case 'register':
                        path = '/Register';
                        break;
                    case 'agreement':
                        path = '/Agreement'
                        break;
                }
                if(path) this.$router.push({path:path});
            },
            //发送验证码
            sendSMSCode(){
                //异步操作


                //重置倒计时
                this.SMSSend = true;
                this.descTime = this.descTimeInit;
            },
            //提交操作
            onSubmit(){
                if(this.submitAble){
                    console.log('可以提交');
                }
            },
            //校验
            validator(val) {
                return this.$A.isMobile(val);
            },
            //获取验证码
            getSMSCode(){
                console.log('获取验证码');
            },
            //计时器开始
            timerStart(){
                if(this.timer) clearInterval(this.timer);
                this.timer = setInterval(()=>{
                    if(this.descTime > 0) this.descTime --;
                },1000)
            },
            //页面初始化
            pageInit(){
                this.winH = document.documentElement.clientHeight || document.body.clientHeight;
            }
        }
    }
</script>

<style lang="scss">
    .registerPage{
        @extend .flex_column;
        overflow: hidden;

        & > div {
            width: 100%;
        }

        .logo {
            /*height: 30%;*/
            @extend .flex_row_center;

            img {
                max-width: 90%;
                object-fit: cover;
                object-position: center;
                /* 最大高度设置 */
                max-height: 90%;
            }
        }

        .form_box {
            @extend .flex_height_auto;
            @extend .flex_column;
            padding: 0 5%;
            width: 90%;
            .van-field__control{
                font-size: 24px;
                /*font-weight: bold;*/
                color: $defaultColor !important;
            }
            .van-field--error .van-field__control, .van-field--error .van-field__control::placeholder{
                color: $warnColor;
            }
            .van-field__control::placeholder{
                font-size: 16px;
            }
            //判断ipad
            @media only screen
            and (min-device-width : 768px)
            and (max-device-width : 1024px){
                font-size: 42px;
            }
            .inputType{
                @extend .flex_row_center;
                img{
                    height: 20px;
                    width: 20px;
                }
            }

            .submit_btn{
                @extend .flex_row_center;
                width: 100%;
                height: 45px;
                border-radius: 50px;
                background: $themeColor;
                font-size: 18px;
                font-weight: bold;
                color: #fff;
                margin-top: 60px;
                transition: all .2s ease;
                &:active{
                    background: $primaryColor;
                }
                &.disabled{
                    background: #EEEEEE;
                    pointer-events:none;
                }
            }
            .agreement{
                margin-top: 12px;
                font-size: 14px;
                @media only screen
                and (min-device-width : 768px)
                and (max-device-width : 1024px){
                    font-size: 26px;
                }
                .agreement_text{
                    color: #1879E5;
                }

            }

        }
    }

</style>